<template>
  <div class="position-relative" id="main_table">
    <v-data-table
      id="table_order"
      :headers="headers"
      :items="items"
      item-key="id"
      :options.sync="options"
      class="table responsive"
      :search="paginate.search"
      :page.sync="paginate.page"
      :items-per-page="paginate.itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <div>
          <table
            id="table_cycle"
            class="table cycle"
            v-if="addition_filter && addition_filter.cycles_th.length > 0"
          >
            <thead>
              <tr>
                <!-- v-if="index <= 6" -->
                <th
                  :class="[activeCycle == cycle.id ? 'active' : '']"
                  v-for="(cycle, index) in addition_filter.cycles_th"
                  :key="index"
                  @click="action(cycle.id, 'select_cycle', cycle)"
                >
                  {{ cycle.name_lang }}
                  <v-icon class="trangile-opacity" size="20"
                    >fas fa-sort-down ml-2</v-icon
                  >
                </th>
                <th v-if="addition_filter.cycles_drop.length > 0">
                  <!-- <v-icon v-if="addition_filter.cycles.length > 3" size="20"
                    >fas fa-angle-down</v-icon
                  > -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="height: 0"
                        color="dark"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="20">mdi-chevron-triple-down</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        :class="[activeCycle == cycle.id ? 'active' : '']"
                        @click="action(cycle.id, 'select_cycle', cycle)"
                        v-for="(cycle, index) in addition_filter.cycles_drop"
                        :key="index"
                      >
                        <v-list-item-title>{{
                          cycle.name_lang
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </template>
      <!-- here -->

      <template v-slot:item.applicant="{ item }" :id="item.id">
        <p>
          <span> {{ item.users.first_name }} {{ item.users.last_name }} </span>
          <span v-if="item.users.customers.p_company_name != null">
            / {{ item.users.customers.p_company_name }}
          </span>
        </p>
      </template>

      <template v-slot:item.stage="{ item }" :id="item.id">
        <p :class="[item.status_id == 4 ? 'text-danger' : '']">
          {{ item.stages.name_lang }}
        </p>
      </template>

      <template v-slot:item.chip="{ item }" :id="item.id">
        <div>
          <v-chip
            v-if="item.statuses.id == 1"
            label
            outlined
            color="orange"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption text-capitalize ls-0">{{
              item.statuses.status
            }}</span>
          </v-chip>
          <v-chip
            v-if="item.statuses.id == 2"
            label
            outlined
            color="blue"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption text-capitalize ls-0">{{
              item.statuses.status
            }}</span>
          </v-chip>
          <v-chip
            v-if="item.statuses.id == 3"
            label
            outlined
            color="green"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption text-capitalize ls-0">{{
              item.statuses.status
            }}</span>
          </v-chip>
          <v-chip
            v-if="item.statuses.id == 4 || item.statuses.id == 5"
            label
            outlined
            color="red"
            class="py-1 px-2 my-0"
          >
            <span class="text-caption ls-0 text-capitalize">{{
              item.statuses.status
            }}</span>
          </v-chip>
        </div>
      </template>

      <template v-slot:item.btns="{ item }" :id="item.id">
        <td class="btn_action text-center">
          <div class="d-flex justify-content-center" :id="item.id">
            <div v-for="(btn, index) in btns" :key="index">
              <div class="text-center" v-if="btn.permission">
                <span
                  v-if="
                    $store.getters['auth/str_per'].indexOf(btn.permission) > -1
                  "
                  :title="$t('general.' + btn.text.replace('_', ' '))"
                  :class="`btn font-weight-bold  mr-4`"
                  @click="action(item.id, btn.text, item)"
                >
                  <!-- <v-btn :title="$t('general.'+btn.text.replace('_',' '))" :class="`w-45 font-weight-bold py-2 px-1 mr-2 text-white ${btn.color}`" -->
                  <!-- $store.state.form.loader -->
                  <v-icon v-if="btn.type == 'icon'">{{ btn.icon }}</v-icon>
                  <div v-else>
                    {{ $t("general." + btn.text.replace("_", " ")) }}
                  </div>
                </span>
              </div>
              <div class="text-center" v-else>
                <span
                  :title="$t('general.' + btn.text.replace('_', ' '))"
                  :class="`btn font-weight-bold  mr-4`"
                  @click="action(item.id, btn.text, item)"
                >
                  <v-icon v-if="btn.type == 'icon'">{{ btn.icon }}</v-icon>
                  <div v-else>
                    {{ $t("general." + btn.text.replace("_", " ")) }}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
    <v-row class="pt-3" id="pagination_table">
      <v-col md="3" v-if="paginate.row_pre_page">
        <div class="d-flex pl-3">
          <label for="" style="font-size: 14px" class="mr-3 mt-2"
            >Rows per Page :
          </label>
          <v-select
            style="width: 36%"
            v-model="paginate.itemsPerPage"
            hide-details
            @change="changeItemPage"
            :items="[10, 20, 30, 50, 100]"
            dense
            outlined
          ></v-select>
        </div>
      </v-col>

      <v-col :md="paginate.row_pre_page ? '9' : '12'" class="text-right">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#06ab95"
          v-model="paginate.page"
          :length="pages"
          @input="handlePageChange"
          total-visible="5"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Table",
  props: [
    "headers",
    "items",
    "btns",
    "loading",
    "data",
    "paginate",
    "addition_filter",
  ],
  data() {
    return {
      search: "",
      options: {},
      activeCycle: null,
      // disabled: true,
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.paginate.total / this.paginate.itemsPerPage) | 0;
    },
    // classLang() {
    //   return {
    //     "text-left": this.$vuetify.rtl,
    //     "text-right": !this.$vuetify.rtl,
    //   };
    // },
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
        this.$emit("set_option", this.options);
      },
      deep: true,
    },
  },
  methods: {
    resetOption() {
      this.options = {
        sortDesc: [],
        sortBy: [],
      };
    },
    handlePageChange(page) {
      this.$emit("get_data_paginate", page);
    },
    changeItemPage($eve) {
      // alert($eve);
      this.$emit("change_pre_page", parseInt($eve));
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    action: function (id, name, item) {
      if (name == "select_cycle") {
        this.activeCycle = id;
      }
      this.$emit("action", id, name, item);
    },
  },
  created() {
    if (this.paginate.row_pre_page == undefined) {
      this.paginate.row_pre_page = true;
    }
  },
};
</script>
<style>
.v-data-table {
  border-radius: 0 !important;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
.theme--light.v-pagination .v-pagination__item--active {
  background-image: linear-gradient(310deg, #047a6f, #2fbcae) !important;
}
</style>