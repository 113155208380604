var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative",attrs:{"id":"main_table"}},[_c('v-data-table',{staticClass:"table responsive",attrs:{"id":"table_order","headers":_vm.headers,"items":_vm.items,"item-key":"id","options":_vm.options,"search":_vm.paginate.search,"page":_vm.paginate.page,"items-per-page":_vm.paginate.itemsPerPage,"hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.$set(_vm.paginate, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',[(_vm.addition_filter && _vm.addition_filter.cycles_th.length > 0)?_c('table',{staticClass:"table cycle",attrs:{"id":"table_cycle"}},[_c('thead',[_c('tr',[_vm._l((_vm.addition_filter.cycles_th),function(cycle,index){return _c('th',{key:index,class:[_vm.activeCycle == cycle.id ? 'active' : ''],on:{"click":function($event){return _vm.action(cycle.id, 'select_cycle', cycle)}}},[_vm._v(" "+_vm._s(cycle.name_lang)+" "),_c('v-icon',{staticClass:"trangile-opacity",attrs:{"size":"20"}},[_vm._v("fas fa-sort-down ml-2")])],1)}),(_vm.addition_filter.cycles_drop.length > 0)?_c('th',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"0"},attrs:{"color":"dark","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-chevron-triple-down")])],1)]}}],null,false,1531981244)},[_c('v-list',_vm._l((_vm.addition_filter.cycles_drop),function(cycle,index){return _c('v-list-item',{key:index,class:[_vm.activeCycle == cycle.id ? 'active' : ''],on:{"click":function($event){return _vm.action(cycle.id, 'select_cycle', cycle)}}},[_c('v-list-item-title',[_vm._v(_vm._s(cycle.name_lang))])],1)}),1)],1)],1):_vm._e()],2)])]):_vm._e()])]},proxy:true},{key:"item.applicant",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('span',[_vm._v(" "+_vm._s(item.users.first_name)+" "+_vm._s(item.users.last_name)+" ")]),(item.users.customers.p_company_name != null)?_c('span',[_vm._v(" / "+_vm._s(item.users.customers.p_company_name)+" ")]):_vm._e()])]}},{key:"item.stage",fn:function(ref){
var item = ref.item;
return [_c('p',{class:[item.status_id == 4 ? 'text-danger' : '']},[_vm._v(" "+_vm._s(item.stages.name_lang)+" ")])]}},{key:"item.chip",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.statuses.id == 1)?_c('v-chip',{staticClass:"py-1 px-2 my-0",attrs:{"label":"","outlined":"","color":"orange"}},[_c('span',{staticClass:"text-caption text-capitalize ls-0"},[_vm._v(_vm._s(item.statuses.status))])]):_vm._e(),(item.statuses.id == 2)?_c('v-chip',{staticClass:"py-1 px-2 my-0",attrs:{"label":"","outlined":"","color":"blue"}},[_c('span',{staticClass:"text-caption text-capitalize ls-0"},[_vm._v(_vm._s(item.statuses.status))])]):_vm._e(),(item.statuses.id == 3)?_c('v-chip',{staticClass:"py-1 px-2 my-0",attrs:{"label":"","outlined":"","color":"green"}},[_c('span',{staticClass:"text-caption text-capitalize ls-0"},[_vm._v(_vm._s(item.statuses.status))])]):_vm._e(),(item.statuses.id == 4 || item.statuses.id == 5)?_c('v-chip',{staticClass:"py-1 px-2 my-0",attrs:{"label":"","outlined":"","color":"red"}},[_c('span',{staticClass:"text-caption ls-0 text-capitalize"},[_vm._v(_vm._s(item.statuses.status))])]):_vm._e()],1)]}},{key:"item.btns",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"btn_action text-center"},[_c('div',{staticClass:"d-flex justify-content-center",attrs:{"id":item.id}},_vm._l((_vm.btns),function(btn,index){return _c('div',{key:index},[(btn.permission)?_c('div',{staticClass:"text-center"},[(
                  _vm.$store.getters['auth/str_per'].indexOf(btn.permission) > -1
                )?_c('span',{class:"btn font-weight-bold  mr-4",attrs:{"title":_vm.$t('general.' + btn.text.replace('_', ' '))},on:{"click":function($event){return _vm.action(item.id, btn.text, item)}}},[(btn.type == 'icon')?_c('v-icon',[_vm._v(_vm._s(btn.icon))]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("general." + btn.text.replace("_", " ")))+" ")])],1):_vm._e()]):_c('div',{staticClass:"text-center"},[_c('span',{class:"btn font-weight-bold  mr-4",attrs:{"title":_vm.$t('general.' + btn.text.replace('_', ' '))},on:{"click":function($event){return _vm.action(item.id, btn.text, item)}}},[(btn.type == 'icon')?_c('v-icon',[_vm._v(_vm._s(btn.icon))]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("general." + btn.text.replace("_", " ")))+" ")])],1)])])}),0)])]}}])}),_c('v-row',{staticClass:"pt-3",attrs:{"id":"pagination_table"}},[(_vm.paginate.row_pre_page)?_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"d-flex pl-3"},[_c('label',{staticClass:"mr-3 mt-2",staticStyle:{"font-size":"14px"},attrs:{"for":""}},[_vm._v("Rows per Page : ")]),_c('v-select',{staticStyle:{"width":"36%"},attrs:{"hide-details":"","items":[10, 20, 30, 50, 100],"dense":"","outlined":""},on:{"change":_vm.changeItemPage},model:{value:(_vm.paginate.itemsPerPage),callback:function ($$v) {_vm.$set(_vm.paginate, "itemsPerPage", $$v)},expression:"paginate.itemsPerPage"}})],1)]):_vm._e(),_c('v-col',{staticClass:"text-right",attrs:{"md":_vm.paginate.row_pre_page ? '9' : '12'}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#06ab95","length":_vm.pages,"total-visible":"5","circle":""},on:{"input":_vm.handlePageChange},model:{value:(_vm.paginate.page),callback:function ($$v) {_vm.$set(_vm.paginate, "page", $$v)},expression:"paginate.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }