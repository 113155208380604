<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="data.dialog" persistent max-width="500">
        <v-card>
          <v-toolbar color="info" dark class="header-info">{{
            $t("order.add inquiries") + " # " + items.id
          }}</v-toolbar>

          <v-card-text class="mt-3">
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-radio-group
                  v-model="form_data.type_radio"
                  :rules="[(v) => !!v || this.$i18n.t('form.Item is required')]"
                  row
                >
                  <v-radio
                    :label="$t('group.Inquiries Group')"
                    value="group"
                    color="#009688"
                  ></v-radio>
                  <v-radio
                    :label="$t('inquiry.Inqieries')"
                    value="inquiry"
                    color="#009688"
                  ></v-radio>
                </v-radio-group>
                <div v-if="form_data.type_radio == 'group'">
                  <label class="text-xs text-typo font-weight-bolder ms-1">{{
                    $t("group.Inquiries Group")
                  }}</label>
                  <v-select
                    v-model="form_data.group_id"
                    :items="items.groups"
                    item-text="name"
                    item-value="id"
                    :label="$t('general.choose')"
                    dense
                    multiple
                    outlined
                    :rules="[
                      (v) =>
                        v.length > 0 || this.$i18n.t('form.Item is required'),
                    ]"
                  ></v-select>
                </div>

                <div v-if="form_data.type_radio == 'inquiry'">
                  <label class="text-xs text-typo font-weight-bolder ms-1">{{
                    $t("inquiry.Inquery Text")
                  }}</label>
                  <v-select
                    v-model="form_data.inquiry_id"
                    :items="items.inqueries"
                    item-text="inquery_lang"
                    item-value="id"
                    :label="$t('general.choose')"
                    dense
                    multiple
                    outlined
                    :rules="[
                      (v) =>
                        v.length > 0 || this.$i18n.t('form.Item is required'),
                    ]"
                  ></v-select>
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="dark" class="bg-light" text @click="close()">
              {{ $t("form.Close") }}
            </v-btn>

            <v-btn
              :loading="data.loading"
              class="btn-primary mw-80"
              color="white"
              text
              @click="save_form()"
            >
              <span slot="loader">
                <v-progress-circular
                  style="width: 20px; height: 20px"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </span>
              {{ $t("form.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapActions } = createNamespacedHelpers("form");
export default {
  props: {
    data: {},
    items: {},
    btn: {
      type: Boolean,
      default: true,
    },
  },
  name: "Modal",
  data() {
    return {
      dialog: false,
      form_data: {
        inquiry_id: "",
        group_id: "",
        type_radio: "",
        id: "",
      },
      valid: true,
      validation: false,
    };
  },
  watch: {
    "data.dialog"(val) {
      // alert(val);
      val || this.close();
    },
  },
  methods: {
    ...mapMutations(["SET_LOADER", "RESET_DATA_FORM"]),
    ...mapActions(["saveFormData", "setFormData"]),
    close: function () {
      this.data.dialog = false;
      this.$refs.form.reset();
    },
    save_form: function () {
      if (this.$refs.form.validate()) {
        this.validation = true;
      } else {
        this.validation = false;
      }
      this.form_data.id = this.items.id;
      this.$emit("save", this.form_data, this.validation);
    },
  },
};
</script>
<style>
.border-gray {
  border: 1px solid #ccc;
}
.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px;
}
</style>