<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify"></Notify>

      <v-row>
        <v-col cols="12">
          <v-card color="light">
            <v-app-bar color="light" class="border-left-primary">
              <v-toolbar-title>{{ $t("order.Orders") }}</v-toolbar-title>
            </v-app-bar>
            <div class="position-relative">
              <Loading v-if="loading"></Loading>
              <FormFilter
                :filters="filters"
                @get_data="get_orders"
                @reset="resetFilters"
              ></FormFilter>
              <div id="btn_row">
                <v-row class="py-0 my-0">
                  <v-col cols="12" sm="6" class="pb-0">
                    <v-btn
                      v-if="btn_table.excel"
                      :ripple="false"
                      class="text-white mt-3 mb-5 font-weight-bolder"
                      small
                      color="#047a6f"
                      :class="classLangExcel"
                      @click="event_btn('excel')"
                      :loading="btn_loading.excel"
                    >
                      <span slot="loader">
                        <v-progress-circular
                          style="width: 20px; height: 20px"
                          indeterminate
                          color="white"
                        ></v-progress-circular>
                      </span>
                      {{ $t("excel") }}
                    </v-btn>
                    <v-btn
                      v-if="btn_table.print"
                      :ripple="false"
                      :class="classLangPrint"
                      class="text-white mt-3 mb-5 font-weight-bolder"
                      small
                      color="#c7373a"
                      @click="event_btn('print')"
                      :loading="btn_loading.print"
                    >
                      <span slot="loader">
                        <v-progress-circular
                          style="width: 20px; height: 20px"
                          indeterminate
                          color="white"
                        ></v-progress-circular>
                      </span>
                      {{ $t("print") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0" :class="classLang">
                    <div
                      class="
                        mx-10
                        mt-0
                        mb-5
                        pt-3
                        text-h5 text-typo
                        font-weight-bolder
                      "
                    >
                      {{ $t("total") }} : {{ paginate.total }}
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <!-- class="pr-0" -->
                <v-col
                  :cols="stages.length > 0 ? '1' : '12'"
                  :class="[stages.length > 0 ? 'pr-0' : '']"
                >
                  <table class="table stage" v-if="stages && stages.length > 0">
                    <thead>
                      <tr v-for="(stage, index) in stages" :key="index">
                        <th
                          :class="[activeStage == stage.id ? 'active' : '']"
                          @click="select_stage(stage)"
                        >
                          {{ stage.name_lang }}
                          <!-- <v-icon class="trangile-opacity" size="25"
                          >mdi-triangle-small-down</v-icon
                        > -->
                        </th>
                      </tr>
                    </thead>
                  </table>
                </v-col>
                <!-- pl-0 -->
                <v-col
                  :cols="stages.length > 0 ? '11' : '12'"
                  :class="[stages.length > 0 ? 'pl-0' : '']"
                >
                  <Table
                    :addition_filter="addition_filter"
                    @change_pre_page="ChangePrePage"
                    @get_data_paginate="handlePageChange"
                    @action="action"
                    @set_option="set_option"
                    :btns="btns"
                    :headers="header"
                    :items="orders"
                    :paginate="paginate"
                    ref="table"
                  ></Table>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <Modal :data="modal_data"></Modal>
        <ModalInquiry
          @save="update_inquiries"
          :data="inq"
          :items="items_inqueries"
        ></ModalInquiry>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Modal from "../Components/New/Modal.vue";
import ModalInquiry from "./ModalAssignInquiery.vue";
import Notify from "../Components/New/Notify.vue";
import Loading from "../Components/Loading.vue";
import FormFilter from "../Components/Filter.vue";
import Table from "./TableOrder.vue";
// import { mapState, mapActions } from 'vuex';
import { createNamespacedHelpers } from "vuex";
const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("order");
export default {
  name: "order-Page",
  components: {
    Table,
    Loading,
    Notify,
    Modal,
    FormFilter,
    ModalInquiry,
  },
  data() {
    return {
      activeStage: null,
      reset_option: false,
      options: {},
      btn_table: {
        excel: true,
        print: true,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      modal_data: {
        size: "500px",
        title: "",
        dialog: false,
      },
      form_cycle: {
        cycle_id: "",
      },

      data_cycle: [
        {
          col: "12",
          type: "select",
          type_select: "single",
          label: this.$i18n.t("cycle.Cycle"),
          title_select: "name_lang",
          value_text: "cycle_id",
          items: [],
          error: null,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
      inq: { dialog: false, loading: false },
      items_inqueries: { groups: [], inqueries: [] },
      btns: [
        {
          type: "icon",
          text: "add_inqueries",
          color: "bg-gradient-secondary",
          icon: "mdi-clipboard-account-outline",
          permission: "order-inquiry",
        },
        {
          type: "icon",
          text: "details",
          color: "bg-gradient-primary",
          icon: "mdi-eye",
          permission: "order-details",
        },
        {
          type: "icon",
          text: "change_status",
          color: "bg-gradient-default",
          icon: "mdi-arrow-up-bold-box-outline",
          permission: "order-change-status",
        },
        {
          type: "icon",
          text: "change_stage",
          color: "bg-gradient-info",
          icon: "mdi-arrow-right-top",
          permission: "order-change-stage",
        },
        {
          type: "icon",
          text: "change_cycle",
          color: "bg-gradient-warning",
          icon: "mdi-artstation",
          permission: "order-change-cycle",
        },
        {
          type: "icon",
          text: "reject_order",
          color: "bg-gradient-danger",
          icon: "mdi-minus-circle",
          permission: "order-reject",
        },
      ],
      header: [
        {
          text: this.$i18n.t("cycle.Cycle"),
          value: "cycles.name_lang",
          align: "center",
        },
        {
          text: this.$i18n.t("order.customer"),
          value: "applicant",
          align: "center",
        },
        {
          text: this.$i18n.t("cycle.stage"),
          value: "stage",
          align: "center",
        },
        {
          text: this.$i18n.t("order.status"),
          value: "chip",
          test: "statuses.status",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      requir_length: 4,
      paginate: {
        page: 1,
        itemsPerPage: 0,
        search: "",
        total: 0,
      },
      filters: [],
      addition_filter: {
        cycles_drop: [],
        cycles_th: [],
      },
      stages: [],
      select_filter: { cycle_id: "", stage: "" },
    };
  },
  computed: {
    ...mapState(["orders", "disabled_item", "items_select"]),
    ...mapGetters(["items_select_cycle"]),
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
    classLangPrint() {
      return {
        "mr-6": this.$vuetify.rtl,
        "ml-6": !this.$vuetify.rtl,
      };
    },
    classLangExcel() {
      return {
        "mr-10": this.$vuetify.rtl,
        "ml-10": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    ...mapActions([
      "getOrdersAction",
      "changeStatus",
      "changeStage",
      "canceleOrder",
      "addInqueries",
    ]),
    ...mapMutations(["SET_DATA", "SET_LOADER"]),

    set_option(val) {
      this.options = val;
    },
    get_orders(reset = false, first = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      formData.append("cycle_id", this.select_filter.cycle_id);
      formData.append("stage", this.select_filter.stage);

      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      if (first) {
        formData.append("first", first);
      }
      this.getOrdersAction({
        page: this.paginate.page,
        data: formData,
        first: first,
      }).then(
        (response) => {
          // console.log(response);
          this.loading = false;
          this.paginate.total = response.data.orders.total;
          this.paginate.itemsPerPage = parseInt(response.data.orders.per_page);
          if (first) {
            if (response.data.cycles) {
              var cycles = response.data.cycles;
              if (cycles.length >= this.requir_length) {
                var cycleth = [];
                var cycledrop = [];
                var requir_length = this.requir_length - 1;
                cycles.map(function (val, ind) {
                  if (ind <= requir_length) {
                    cycleth.push(val);
                  } else {
                    cycledrop.push(val);
                  }
                });
                this.addition_filter.cycles_th = cycleth;
                this.addition_filter.cycles_drop = cycledrop;
              } else {
                this.addition_filter.cycles_th = cycles;
              }
            }
          }
          if (response.data.stages) {
            this.stages = response.data.stages;
          }

          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.reset_option = true;
            this.$refs.table.resetOption();
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status != 401) {
            this.$store.dispatch("form/setNotify", {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        }
      );
    },
    action(id, name, item) {
      if (name == "change_cycle") {
        this.change_cycle(item);
      } else if (name == "change_status") {
        this.change_status(id, item);
      } else if (name == "change_stage") {
        this.change_stage(id, item);
      } else if (name == "reject_order") {
        this.reject_order(id, item);
      } else if (name == "details") {
        this.details(id, item);
      } else if (name == "add_inqueries") {
        this.add_inqueries(item);
      } else if (name == "select_cycle") {
        this.select_cycle(item);
      }
    },
    select_stage(item) {
      this.activeStage = item.id;
      this.select_filter.stage = item.id;
      this.get_orders();
    },
    select_cycle(item) {
      this.select_filter.cycle_id = item.id;
      this.get_orders();
    },
    details(id, item) {
      if (item.status_id == 4) {
        this.message = this.$i18n.t("this item is rejected");

        this.$store.dispatch("form/setNotify", {
          msg: this.message,
          type: "Danger",
        });
      } else {
        this.$router.push({ path: `/order/details-order/${id.toString()}` });
      }
    },
    change_status(id, item) {
      if (item.status_id == 4) {
        this.message = this.$i18n.t("this item is rejected");

        this.$store.dispatch("form/setNotify", {
          msg: this.message,
          type: "Danger",
        });
      } else {
        this.$swal({
          title: this.$i18n.t("general.Are you sure?!"),
          text: this.$i18n.t("You won't be able to revert this!"),
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: this.$i18n.t("general.Yes, change it!"),
          cancelButtonText: this.$i18n.t("general.No, cancel!"),
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.changeStatus(id).then(
              (response) => {
                this.$swal.fire(
                  this.$i18n.t("general.Changed!"),
                  response.data.message,
                  "success"
                );
                // console.log(response);
              },
              (error) => {
                console.log(error);
                this.$swal.fire(
                  this.$i18n.t("general.Error"),
                  this.$i18n.t("general.There error please try again"),
                  "error"
                );
              }
            );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.fire(
              this.$i18n.t("general.Cancelled"),
              this.$i18n.t("general.Cancelled Change"),
              "error"
            );
          }
        });
      }
    },
    change_stage(id, item) {
      if (item.status_id == 4) {
        this.message = this.$i18n.t("this item is rejected");

        this.$store.dispatch("form/setNotify", {
          msg: this.message,
          type: "Danger",
        });
      } else {
        this.$swal({
          title: this.$i18n.t("general.Are you sure?!"),
          text: this.$i18n.t("You won't be able to revert this!"),
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: this.$i18n.t("general.Yes, change it!"),
          cancelButtonText: this.$i18n.t("general.No, cancel!"),
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.changeStage(id).then(
              (response) => {
                // console.log(response)
                if (response.data.code == "502") {
                  this.$swal.fire(
                    this.$i18n.t("general.Warning"),
                    response.data.message,
                    "warning"
                  );
                } else {
                  this.$swal.fire(
                    this.$i18n.t("general.Changed!"),
                    response.data.message,
                    "success"
                  );
                }
              },
              (error) => {
                console.log(error);

                this.$swal.fire(
                  this.$i18n.t("general.Error"),
                  this.$i18n.t("general.There error please try again"),
                  "error"
                );
              }
            );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.fire(
              this.$i18n.t("general.Cancelled"),
              this.$i18n.t("general.Cancelled Change"),
              "error"
            );
          }
        });
      }
    },
    reject_order(id, item) {
      if (item.status_id == 4) {
        this.message = this.$i18n.t("this item is rejected");

        this.$store.dispatch("form/setNotify", {
          msg: this.message,
          type: "Danger",
        });
      } else {
        this.$swal({
          title: this.$i18n.t("general.Are you sure?!"),
          text: this.$i18n.t("You won't be able to revert this!"),
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: this.$i18n.t("general.Yes, reject it!"),
          cancelButtonText: this.$i18n.t("general.No, cancel!"),
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.canceleOrder(id).then(
              (response) => {
                // console.log(response)
                this.$swal.fire(
                  this.$i18n.t("general.rejected order!"),
                  response.data.message,
                  "success"
                );
              },
              (error) => {
                console.log(error);
                this.$swal.fire(
                  this.$i18n.t("general.Error"),
                  this.$i18n.t("general.There error please try again"),
                  "error"
                );
              }
            );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.fire(
              this.$i18n.t("general.Cancelled"),
              this.$i18n.t("general.Cancelled Reject"),
              "error"
            );
          }
        });
      }
    },
    change_cycle(item) {
      console.log(item);
      if (item.status_id == 4) {
        this.message = this.$i18n.t("this item is rejected");

        this.$store.dispatch("form/setNotify", {
          msg: this.message,
          type: "Danger",
        });
      } else {
        this.$store.dispatch("form/setFormData", this.form_cycle);
        this.$store.dispatch("form/setData", this.data_cycle);
        this.modal_data.title =
          this.$i18n.t("general.change cycle") +
          " - " +
          item.cycles.name_lang +
          " - " +
          this.$i18n.t("order.for order") +
          " # " +
          item.id;
        this.$store.commit("form/SET_DIALOG", true);
        this.SET_DATA({ id: item.cycle_id });
        this.$store.state.form.style_form[0].items = this.items_select_cycle;
        // send name function in module
        this.$store.commit("form/INFO_CALL", {
          name: "order/changeCycle",
          id: item.id,
        });
      }
    },
    add_inqueries(item) {
      if (item.status_id == 4) {
        this.message = this.$i18n.t("this item is rejected");

        this.$store.dispatch("form/setNotify", {
          msg: this.message,
          type: "Danger",
        });
      } else {
        this.inq.dialog = true;
        this.items_inqueries.groups = this.items_select.inquiry_group;
        this.items_inqueries.inqueries = this.items_select.inqueries;
        this.items_inqueries.id = item.id;
      }
    },
    update_inquiries(data, valid) {
      this.inq.loading = true;
      if (valid) {
        this.addInqueries(data).then(
          () => {
            this.inq.dialog = false;
            this.inq.loading = false;
          },
          () => {
            this.inq.loading = false;
          }
        );
      } else {
        this.inq.loading = false;
      }
    },
    handlePageChange(page) {
      console.log(page);
      this.paginate.page = page;
      this.get_orders();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_orders(false, false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.get_orders(true);
    },
    event_btn(name) {
      if (name == "excel") {
        this.export_excel();
      } else if (name == "print") {
        this.print_table();
      }
    },
    print_table() {
      var table = document.getElementsByTagName("table")[1];

      console.log(table);
      setTimeout(() => {
        var newWin = window.open("");
        var data = `<html><head><title>table</title>
          <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">
          <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/buttons/2.2.3/css/buttons.dataTables.min.css">
          <link rel="stylesheet" type="text/css" href="${this.$baseURL}css/table.css">
          <style>
          #table_cycle{
            display:none
          }
          </style>
          </head>
          <body class="dt-print-view">
            <table class="dataTable">
              ${table.innerHTML}
              </table>
            </body>
          </html>`;
        newWin.document.write(data);
        setTimeout(() => {
          newWin.print();
          newWin.close();
        }, 8000);
      }, 200);
    },
    export_excel() {
      this.btn_loading.excel = true;
      const formData = new FormData();
      this.filters.filter(function (filter) {
        formData.append(filter.name, filter.value);
      });
      this.$http({
        url: this.$baseURL + "api/orders/export",
        responseType: "blob",
        method: "post",
        data: formData,
      }).then(
        (response) => {
          this.btn_loading.excel = false;
          // console.log(response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Applications_" + new Date().toLocaleString() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.btn_loading.excel = false;
          if (error.response.status != 401) {
            this.$store.dispatch("form/setNotify", {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        }
      );
    },
  },
  mounted() {
    this.get_orders(true, true);
    document.title = this.$i18n.t("order.Orders");
  },
};
</script>
